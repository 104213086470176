





























































































































































































































































































.group_form {
  display: flex;
  & .tf_comm {
  }
}
