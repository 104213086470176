
































































































































































































































.group_form::v-deep {
  display: flex;
  & > .dialogue_comm {
    display: block;
    width: 100%;
  }
  & .dialogue_head {
    display: flex;
  }
}
